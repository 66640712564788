import React from "react"
import BlockContent from "../blockContent"

import Image from "./Image"

const ImageText = ({ alt, image, text}) => {
  return (
    <>
      <Image image={image} alt={alt} className="it-image"/>
      <BlockContent blocks={text || []} className="it-text"/>
    </>
  )
}

export default ImageText
