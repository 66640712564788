import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "gatsby"


const Team = ({ team, title }) => {
  const
    { t, i18n } = useTranslation('common'),
    half = Math.ceil(team.length / 2),
    firstHalf = team?.slice(0, half),
    secondHalf = team?.slice(-half);

  return (
    <section className="grid-20 project--team">
      <Link className="M16-regular back" to={`/${i18n.language}/work`}>{t("back")}</Link>

      <h2 data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="M60-regular">{title}</h2>

      <div data-sal='fade' data-sal-duration='500' data-sal-easing='ease' className="team-column one">
        {firstHalf?.map(member => {
          return (
            <div key={member._key}>
              <p className="M16-regular name">{member.name}</p>
              <p className="M16-light credit">{member.credit}</p>
            </div>
          )
        })}
      </div>

      <div data-sal='fade' data-sal-duration='500' data-sal-easing='ease' className="team-column two">
        {secondHalf?.map(member => {
          return (
            <div key={member._key}>
              <p className="M16-regular name">{member.name}</p>
              <p className="M16-light credit">{member.credit}</p>
            </div>
          )
        })}
      </div>

    </section>
  )
}

export default Team
