import React from "react"
import classNames from "classnames"

import Image from "./Image"

const Images = ({ layout, left, right, alt }) => {
  let
    leftClasses = classNames({
      'img-vertical  c-left': layout === "vertical",
      'img-square  c-left': layout === "square",
      'img-horizontal  c-left': layout === "horizontal",
      'img-vertical-other  c-left': layout === "other"
    }),
    rightClasses = classNames({
      'img-vertical c-right': layout === "vertical",
      'img-square-horizontal c-right': layout === "square",
      'img-horizontal c-right': layout === "horizontal",
      'img-horizontal-other c-right': layout === "other"
    })

  return (
    <>
    {!!left &&
      <Image alt={alt} image={left} className={leftClasses}/>
    }
    {!!right &&
      <Image alt={alt} image={right} className={rightClasses}/>
    }
    </>
  )
}

export default Images
