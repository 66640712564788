import React from "react"

import Project from "../project"

const Related = ({ title, related }) => {
  return (
    <section className="grid-20 project--related">
      <h3 data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="M24-light">{title}</h3>
      <div data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="full-row-three">
        {related.map((project, i)=> (
          <Project key={project._id} project={project} index={i + 5}/>
        ))}
      </div>
    </section>
  )
}

export default Related
