import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { localize } from "../lib/helpers"
import loadable from '@loadable/component'

import Layout from "../components/layout"
import Seo from "../components/seo"
import Info from "../components/Project/Info"
import BlockContent from "../components/blockContent"
import Image from "../components/Project/Image"
import Images from "../components/Project/Images"
import ImageText from "../components/Project/ImageText"
import VideoNoControls from "../components/videoNoControls"
import Team from "../components/Project/Team"
import Related from "../components/Project/Related"

const Slider = loadable(() => import("../components/Project/Slider"));

const ProjectPage = ({ data, pageContext, location }) => {
  const
    { title, _rawLiner, _rawDescription,
      category, link, _rawComponents, _rawTeam } = localize(data.project, [pageContext.language]);
  const { _rawTeam: team, _rawRelated } = localize(data.team, [pageContext.language]);
  const related = localize(data.related.nodes, [pageContext.language]);

  const content = (_rawComponents || [])
    .map((c, i) => {
      let el = null;
      switch (c._type) {
        case "image":
          el = <Image key={c._key} className="image-full" image={c} alt={`${title} | Latente Studio`}/>;
          break;

        case "textThree":
          el = <p data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="TT60-thin text-13" key={c._key}>{c.text}</p>;
          break;

        case "images":
          el = <Images key={c._key} layout={c.layout} left={c.left} right={c.right} alt={`${title} | Latente Studio`}/>;
          break;

        case "imageText":
          el = <ImageText key={c._key} {...c} alt={`${title} | Latente Studio`}/>;
          break;

        case "textFullWidth":
          el = <p data-sal='fade' data-sal-duration='750' data-sal-easing='ease' key={c._key} className="TT60-thin text-full-width">{c.text}</p>;
          break;

        case "my_link":
          el = <p data-sal='fade' data-sal-duration='750' data-sal-easing='ease' key={c._key} className="TT60-thin text-full-width">
                <BlockContent blocks={c.url || []}/>
              </p>;
          break;

        case "textSix":
          el = <BlockContent key={c._key} blocks={c.text || []} className="M28-light text-6"/>;
          break;

        case "video":
          el = <VideoNoControls key={c._key} video={c.vimeoVideo.oEmbedData.html} classNames="project-video"/>;
          break;

        case "slider":
          el = <Slider key={c._key} slides={c.slides} alt={`${title} | Latente Studio`}/>;
          break;

        default:
          el = null;
      }
      return el;
    });

    useEffect(() => {
      let allLinks = document.querySelectorAll('.text-full-width a');

      if (allLinks) {
        allLinks.forEach(link => {
          link.setAttribute('target', '_blank');
        })
      }
    })

  return (
    <Layout>
      <Seo title={title} lang={pageContext.language} location={location}/>
      <div id="project" className="grid-20">
        <Info title={title} category={category} link={link} _rawDescription={_rawDescription} _rawLiner={_rawLiner}/>
        {content}
        <Team title={team} team={_rawTeam}/>
        <Related title={_rawRelated} related={related}/>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query Project($slug: String!) {
    project: sanityProject(slug: {current: {eq: $slug}}) {
      title
      _rawLiner
      _rawDescription(resolveReferences: {maxDepth: 5})
      category
      link
      _rawComponents(resolveReferences: {maxDepth: 5})
      _rawTeam(resolveReferences: {maxDepth: 5})
    }
    team: sanityWork {
      _rawTeam
      _rawRelated
    }

    related: allSanityProject(filter: {slug: {current: {ne: $slug}}}, limit: 3) {
      nodes {
        title
        slug {
          current
        }
        covers {
          asset {
            gatsbyImageData(width: 980 layout: CONSTRAINED)
          }
        }
      }
    }
  }
`

export default ProjectPage
