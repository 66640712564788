import React from "react"
import { useTranslation } from "react-i18next"
import { window } from 'browser-monads'
import smoothscroll from 'smoothscroll-polyfill'

import BlockContent from "../blockContent"
import Arrow from "../../assets/svg/cta_arrow.svg"

const Info = ({ title, _rawLiner, category, link, _rawDescription }) => {
  const { t } = useTranslation('common')

  const scrollTop = () => {
    window.__forceSmoothScrollPolyfill__ = true;
    smoothscroll.polyfill()
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  return (
    <section className=" grid-20 info">
      <h1 data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="M60-regular">{title}</h1>
      <h2 data-sal='fade' data-sal-duration='500' data-sal-easing='ease' className="M36-Book">{_rawLiner}</h2>
      <ul>
        <React.Fragment>
          {category?.map((cat, i) => (
            <li data-sal='fade' data-sal-duration='500' data-sal-easing='ease' className="M16-regular category" key={`cat-${i}`}>{cat}</li>
          ))}
          {link && Object.keys(link).length > 0 &&
            <li data-sal='fade' data-sal-duration='500' data-sal-easing='ease' className="link-project"><a rel="noreferrer" className="cta" href={link} target="_blank">{t("link")} <Arrow className="arrow"/></a></li>
          }
        </React.Fragment>
      </ul>
      <BlockContent blocks={_rawDescription || []} className="M24-light description"/>
      <button title="scroll to top" type="button" onClick={scrollTop} onKeyDown={scrollTop}>
        Top
      </button>
  </section>
  )
}

export default Info
